import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "./bio"
import Layout from "./layout"
import SEO from "./seo"
import postData from "../../content/yaml/data.yaml"
import { FaExternalLinkAlt } from "react-icons/fa"

const allTags = {}
postData.tags.forEach(tagData => {
  allTags[tagData.slug] = tagData
})

const ExternalLink = ({ post }) => (
  <li key={post.url} style={{ display: `flex` }}>
    <article
      className="post-list-item no-top"
      itemScope
      itemType="http://schema.org/Article"
      style={{ flex: 7, paddingRight: `50px` }}
    >
      <header>
        <h2 style={{ fontStyle: "italic", fontWeight: 300 }}>
          <a href={post.url} target="_blank" itemProp="url">
            <span itemProp="headline">{post.title}</span>
            &nbsp;
            <span style={{ fontSize: "0.75rem" }}>
              <FaExternalLinkAlt />
            </span>
          </a>
        </h2>
        {post.tag ? <Tag tag={allTags[post.tag]} number={post.number} /> : null}
      </header>
    </article>
    <small
      style={{
        flex: 0,
        minWidth: 70,
        lineHeight: `1.9rem`,
        color: `#666`,
      }}
    >
      {post.dateStr}
    </small>
  </li>
)

const Tag = ({ tag, number }) => (
  <Link
    to={`/tags/${tag.slug}`}
    style={{
      display: `inline-block`,
      background: tag.background,
      color: "#333",
      textDecoration: `none`,
      borderRadius: 10,
      padding: "3px 10px",
      textTransform: `uppercase`,
      fontSize: 12,
    }}
  >
    <span style={{ borderBottom: `1px solid black` }}>
      {tag.title}
      {number ? ` #${number}` : null}
    </span>
  </Link>
)

const PostPreview = ({ post }) => {
  const title = post.frontmatter.title || post.fields.slug
  console.log(post.frontmatter.tags)
  console.log(allTags)
  return (
    <li key={post.fields.slug} style={{ display: "flex" }}>
      <article
        className="post-list-item no-top"
        itemScope
        itemType="http://schema.org/Article"
        style={{ flex: 7, paddingRight: `50px` }}
      >
        <header>
          <h2>
            <Link to={post.fields.slug} itemProp="url">
              <span itemProp="headline">{title}</span>
            </Link>
          </h2>
          {post.frontmatter.tags ? (
            <>
              {post.frontmatter.tags.map((tag, i) => (
                <Tag
                  tag={allTags[tag]}
                  number={!i && post.frontmatter.number}
                />
              ))}
            </>
          ) : null}
        </header>
        <section>
          <p
            dangerouslySetInnerHTML={{
              __html: post.frontmatter.description || post.excerpt,
            }}
            itemProp="description"
          />
        </section>
      </article>
      <small
        style={{
          flex: 0,
          minWidth: 70,
          lineHeight: `1.9rem`,
          color: `#666`,
        }}
      >
        {post.frontmatter.date}
      </small>
    </li>
  )
}

const BlogIndex = ({ data, location, slug }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const shouldPublishPost = node =>
    !(node.frontmatter.public === false) && !node.frontmatter.draft
  const posts = data.allMarkdownRemark.nodes.filter(node =>
    shouldPublishPost(node)
  )

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="All posts" />
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  const allPosts = posts
    .concat(postData.external_posts.filter(post => !slug || post.tag === slug))
    .sort(
      (a, b) =>
        new Date(b?.frontmatter?.dateInt || b.date) -
        new Date(a?.frontmatter?.dateInt || a.date)
    )

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      {slug ? <h1>{`Tags // ${allTags[slug].title}`}</h1> : <Bio />}

      <ol style={{ listStyle: `none` }}>
        {allPosts.map(post =>
          post.frontmatter ? (
            <PostPreview post={post} />
          ) : (
            <ExternalLink post={post} />
          )
        )}
      </ol>
    </Layout>
  )
}

export default BlogIndex
