import * as React from "react"
import { graphql } from "gatsby"

import Posts from "../components/posts"

const BlogIndex = ({ data, location, pageContext: { slug } }) => (
  <Posts data={data} location={location} slug={slug} />
)

export default BlogIndex

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { tags: { in: [$slug] } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMM.DD.YY")
          dateInt: date
          title
          description
          draft
          public
          tags
          number
        }
      }
    }
  }
`
